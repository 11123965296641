import UsersDB from '@/firebase/users-db'

/**
 * Create new user from firebase auth user infos
 */
// eslint-disable-next-line
export const createNewUserFromFirebaseAuthUser = async firebaseAuthUser => {
  const providerData = firebaseAuthUser.providerData[0]
  const { displayName, photoURL, email } = providerData
  const userDb = new UsersDB()
  const user = {
    displayName,
    photoURL,
    email,
    currEcopledge: 'initial'
  }

  return await userDb.create(user, firebaseAuthUser.uid)
}

// user role enum
// export const Role = {
//     Admin: 'Admin',
//     User: 'User'
// }