export default {
  /* RECORD input name */

  setRecordToCreate: (state, recordToCreate) =>
    (state.recordToCreate = recordToCreate),

  /* RECORDS */
  setRecords: (state, records) => (state.records = records),
  addRecord: (state, record) => state.records.push(record),
  removeRecordById: (state, recordId) => {
    const index = state.records.findIndex(record => record.id === recordId)
    state.records.splice(index, 1)
  },

  /* RECORD deletion */
  addRecordDeletionPending: (state, recordId) =>
    state.recordDeletionPending.push(recordId),
  removeRecordDeletionPending: (state, recordId) => {
    const index = state.records.findIndex(record => record.id === recordId)
    state.recordDeletionPending.splice(index, 1)
  },

  /* RECORD creation */
  setRecordCreationPending: (state, value) =>
    (state.recordCreationPending = value)
}
