import { find } from 'lodash'

export default {
  /**
   * Check if an ecopledge has deletion pending
   */
  isEcopledgeDeletionPending: state => ecopledgeId =>
    state.ecopledgeDeletionPending.includes(ecopledgeId),

  /**
   * Get ecopledge by id
   */
  getEcopledgeById: state => ecopledgeId =>
    find(state.ecopledges, ecopledge => ecopledge.id === ecopledgeId),

  getEcopledgeId: state => {
    return state.ecopledge
}
}