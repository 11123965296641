import state from './records.state'
import mutations from './records.mutations'
import actions from './records.actions'
import getters from './records.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
