import EcopledgeRecordsDB from '@/firebase/user-records-db'

export default {
  /**
   * Fetch records of ecopledges of current signed in user
   */
  getEcopledgeRecords: async ({ rootState, commit }) => {
    const ecopledgeRecordDb = new EcopledgeRecordsDB(rootState.authentication.user.id, rootState.authentication.user.currEcopledge)
    const records = await ecopledgeRecordDb.readAll()
    commit('setRecords', records)
  },

  /**
   * Create a record for current ecopledge for current signed in user
   */
  createRecord: async ({ commit, rootState }, record) => {
    // alert("in createRecord")
    const recordDb = new EcopledgeRecordsDB(rootState.authentication.user.id, rootState.authentication.user.currEcopledge)

    commit('setRecordCreationPending', true)
    const createdRecord = await recordDb.create(record)
    // console.log('about to add record')
    commit('addRecord', createdRecord)
    commit('setRecordCreationPending', false)
  },

  /**
   * Create a new record for ecopledge for current signed user and reset ecopledge name input
   */
  triggerAddRecordAction: ({ dispatch, state, commit }, record) => {
        // console.log('trigger')
    // commit('createRecord', '')
    dispatch('createRecord', record)
  },

  /**
   * Delete an ecopledge/record from its id
   */
  deleteRecord: async ({ rootState, commit, getters }, recordId) => {
    if (getters.isRecordDeletionPending(recordId)) return

    const EcopledgeRecordsDb = new EcopledgeRecordsDB(rootState.authentication.user.id)

    commit('addRecordDeletionPending', recordId)
    await EcopledgeRecordsDb.delete(recordId)
    commit('removeRecordById', recordId)
    commit('removeRecordDeletionPending', recordId)
  }
}
