<template>
  <v-content>
    <header class="navbar" :class="{ offline: !networkOnLine }">
      <v-navigation-drawer
        v-model="drawer"
        class="purple lighten-2"
        width="150"
        app
        dark
        temporary
        mobile-break-point="1050"
      >
        <v-list class="pa-0">
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-img :src="images.logo" height="50px"></v-img>
            </v-list-tile-avatar>
          </v-list-tile>
              <!-- <v-list-tile-content class="purple">iBeck Consulting</v-list-tile-content> -->
          <v-list-tile v-for="item in items" :key="item.title" :to="item.link">
            <v-list-tile-content>{{ item.title }}</v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-navigation-drawer>
      <v-toolbar fixed app flat clipped-left hidden-sm-and-down>
         <a href="https://www.ibeck.me">
          <v-img
            class="hidden-md-and-up"
            :src="images.logo"
            height="50px"
            to="/"
          >
          </v-img>
        </a>
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-img :src="images.logo" height="45px"></v-img>
          </v-list-tile-avatar>
        </v-list-tile>
        <v-spacer></v-spacer>
        
        <v-toolbar-side-icon
          class="hidden-md-and-up"
          @click="drawer = !drawer"
        ></v-toolbar-side-icon>
        <div class="text-xs-center pr-3 hidden-sm-and-down">
          <v-btn flat large round to="/" color="purple">
            Home
          </v-btn>
          <!-- <v-btn flat large round to="/services" color="purple">
            services
          </v-btn> -->
          <v-btn flat large round to="/strategy" color="purple">
            strategy
          </v-btn>
          <v-btn flat large round to="/development" color="purple">
            development
          </v-btn>
          <v-btn flat large round to="/branding" color="purple">
            branding
          </v-btn>
          <v-btn flat large round to="/support" color="purple">
            support
          </v-btn>
          <v-btn flat large round to="/about" color="purple">
            About
          </v-btn>
          <v-btn flat large round to="/admindashboard" color="purple">
            Customer
          </v-btn>
          <div v-if="!networkOnLine">Offline</div>
        </div>
      </v-toolbar>
    </header>
  </v-content>
</template>

<script>
import firebase from 'firebase/app'
import { mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {
      drawer: false,
      menuState: 'out',
      // user: null,
      isAuth: false,
      activeBtn: 1,
      showNav: false,
      dialog: false,
      mini: false,
      dialogText: '',
      dialogTitle: '',
      isRootComponent: true,
      clipped: false,
      fixed: false,
      items: [
        {
          icon: '',
          title: 'Home',
          vertical: 'Home',
          link: '/'
        },
        // {
        //   icon: '',
        //   title: 'Services',
        //   vertical: 'Services',
        //   link: 'Services'
        // },
        {
          icon: '',
          title: 'Strategy',
          vertical: 'Strategy',
          link: 'Strategy'
        },
        {
          icon: '',
          title: 'Development',
          vertical: 'Development',
          link: 'Development'
        },
        {
          icon: '',
          title: 'Branding',
          vertical: 'Branding',
          link: 'Branding'
        },
        {
          icon: '',
          title: 'Support',
          vertical: 'Support',
          link: 'Support'
        },
        {
          icon: '',
          title: 'About',
          vertical: 'About',
          link: 'about'
        },
        // {
        //   icon: '',
        //   title: 'Contact Us',
        //   vertical: 'ContactUs',
        //   link: 'contact'
        // },
        {
          icon: '',
          title: 'Customer',
          vertical: 'AdminDashboard',
          link: 'admindashboard'
        },
        // {
        //   icon: '',
        //   title: 'School',
        //   vertical: 'OurDashboard',
        //   link: 'ourdashboard'
        // }
      ],
      // adminMenu: [
      //   {
      //     title: 'Enquiries',
      //     link: 'enquirylist'
      //   },
      //   {
      //     title: 'EcoPledges',
      //     link: 'enquirylist'
      //   },
      //   {
      //     title: 'Donations',
      //     link: 'enquirylist'
      //   },
      //   {
      //     title: 'Settings',
      //     link: 'enquirylist'
      //   }
      // ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      menuItem: 'Orders',
      images: {
        logo: require('@/assets/logo.png')
      }
    }
  },
  computed: {
    ...mapGetters('authentication', ['isUserSignedIn']),
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle']),
    activeMenuItem() {
      return this.menuItem
    }
  },
  methods: {
    async signout() {
      await firebase.auth().signOut()
    },
    clickMenu(item) {
      this.menuItem = item.title
      this.$router.push({
        name: item.title
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
.v-content {
  padding: 0 !important;
}
.v-app {
  padding: 0 !important;
}
.v-container {
  padding: 0 !important;
}
.container {
  padding: 0 !important;
}
.content {
  padding: 0 !important;
}
#nav {
  padding: 5px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
