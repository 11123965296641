import firebase from 'firebase'
import Vue from 'vue'

import Vuetify from 'vuetify'
import moment from 'moment'

import App from './App.vue'
import router from './router'
import store from './store'
import '@/misc/register-service-worker'
import '@/misc/handle-network-status'
import '@/firebase/init'
import '@/firebase/authentication'
import '@/misc/handle-apple-install-prompt'
import 'pwacompat'


import i18n from './i18n'
import 'vuetify/dist/vuetify.min.css'

Vue.config.productionTip = false
let app = ''
Vue.use(Vuetify)

Vue.filter('formatDate', value => {
  // alert("in formatDate filter")
  // const date = value.toDate();
  return moment(value).format('DD-MMM-YY')
})
firebase.auth().onAuthStateChanged(() => {
  if(!app) {
    app = new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  }
})