<template>
  <v-container fluid grid-list-md>
      <v-alert type="success">
      I'm a success alert.
    </v-alert>
    <v-alert
      prominent
      type="info"
      color="purple"
      v-model="alert"
    >
      <v-row align="center">
        <v-col class="grow">Many of our customers are activiting their Business Continunity plans during the COV-19 pandamic.  Ensure they are up-to-date.</v-col>
        <!-- <v-spacer></v-spacer> -->
        <!-- <v-col class="shrink">
          <v-btn>Find out more</v-btn>
        </v-col> -->
      </v-row>
    </v-alert>

    <v-layout
      align-space-between
      justify-center
      row wrap
      grey darken-1
      fill-height
    >
      <v-card pb-4 flat color="grey darken-1" dark class="justify-center">
        <div style="text-align:center">
          <v-card-title
            primary class="hidden-sm-and-down justify-center display-3"
            >{{ $t('home.header1') }}</v-card-title
          >
          <v-card-title
            primary class="hidden-md-and-up justify-center title"
            >{{ $t('home.header1') }}</v-card-title
          >
          <v-card-title
            primary class="hidden-sm-and-down justify-center display-1"
            >{{ $t('home.header2') }}</v-card-title
          >
          <v-card-title
            primary class="hidden-md-and-up justify-center title"
            >{{ $t('home.header2') }}</v-card-title
          >
          <v-card-title
            primary class="hidden-sm-and-down justify-center title"
            >{{ $t('home.header3') }}</v-card-title
          >
          <v-card-title
            primary class="hidden-md-and-up justify-center title"
            >{{ $t('home.header3') }}</v-card-title
          >

        </div>
      </v-card>
    </v-layout>
    <v-layout
      align-space-between
      justify-center
      row wrap
      grey darken-1
      fill-height
      pb-4
    >
      <v-flex d-flex xs12 sm6 md2>
        <v-card flat color="grey darken-1" dark>
          <div style="text-align:center">
            <div>
              <v-btn v-if='this.$route.path == "/"' color="white" @click="() => this.$vuetify.goTo('#foo')" fab class="mt-5 purple">
                <v-icon large color="white">trending_up</v-icon>
              </v-btn>
              <v-btn v-else color="white" @click="() => this.$router.push('/strategy')" fab class="mt-5 purple">
                <v-icon large color="white">trending_up</v-icon>
              </v-btn>
            </div>
            <!-- <div class="subheading">{{ $t('home.strategy') }}</div> -->
            <v-card flat color="grey darken-1" dark>
              <div style="text-align:center">
                <!-- <v-img :src="images.education" height="200px" /> -->
                <div style="text-align:center">
                  <div class="headline white--text">{{ $t('services.header1') }}</div>
                  <div style="height:20px"></div>
                  <p class="body-1 white--text">{{ $t('services.sub11') }}</p>
                  <p class="body-1 white--text">{{ $t('services.sub12') }}</p>
                  <p class="body-1 white--text">{{ $t('services.sub13') }}</p>
                  <p class="body-1 white--text">{{ $t('services.sub14') }}</p>
                  <p class="body-1 white--text">{{ $t('services.sub15') }}</p>
                </div>
              </div>
            </v-card>
          </div>
        </v-card>
      </v-flex>
      <v-flex d-flex xs12 sm6 md2>
        <v-card flat color="grey darken-1" dark>
          <div style="text-align:center">
            <div>
              <v-btn v-if='this.$route.path == "/"' color="white" @click="() => this.$vuetify.goTo('#foo')" fab class="mt-5 purple">
                <v-icon large color="white">shopping_cart</v-icon>
              </v-btn>
              <v-btn v-else color="white" @click="() => this.$router.push('/development')" fab class="mt-5 purple">
                <v-icon large color="white">shopping_cart</v-icon>
              </v-btn>
            </div>
            <!-- <div class="subheading">{{ $t('home.development') }}</div> -->
            <v-card flat color="grey darken-1" dark>
              <div style="text-align:center">
                <!-- <v-img :src="images.workshop" height="200px" /> -->
                <div style="text-align:center">
                  <div class="headline white--text">{{ $t('services.header2') }}</div>
                  <div style="height:20px"></div>
                  <p class="body-1 white--text">{{ $t('services.sub21') }}</p>
                  <p class="body-1 white--text">{{ $t('services.sub22') }}</p>
                  <p class="body-1 white--text">{{ $t('services.sub23') }}</p>
                  <p class="body-1 white--text">{{ $t('services.sub24') }}</p>
                  <p class="body-1 white--text">{{ $t('services.sub25') }}</p>
                </div>
              </div>
            </v-card>
          </div>
        </v-card>
      </v-flex>
      <v-flex d-flex xs12 sm6 md2>
        <v-card flat color="grey darken-1" dark>
          <div style="text-align:center">
            <div>
              <v-btn v-if='this.$route.path == "/"' color="white" @click="() => this.$vuetify.goTo('#foo')" fab class="mt-5 purple">
                <v-icon large color="white">done</v-icon>
              </v-btn>
              <v-btn v-else color="white" @click="() => this.$router.push('/branding')" fab class="mt-5 purple">
                <v-icon large color="white">done</v-icon>
              </v-btn>
            </div>
            <!-- <div class="subheading">{{ $t('home.branding') }}</div> -->
            <v-card flat color="grey darken-1" dark>
              <div style="text-align:center">
                <!-- <v-img :src="images.other" height="200px" /> -->
                <div style="text-align:center">
                  <div class="headline white--text">{{ $t('services.header3') }}</div>
                  <div style="height:20px"></div>
                  <p class="body-1 white--text">{{ $t('services.sub31') }}</p>
                  <p class="body-1 white--text">{{ $t('services.sub32') }}</p>
                  <p class="body-1 white--text">{{ $t('services.sub33') }}</p>
                  <p class="body-1 white--text">{{ $t('services.sub34') }}</p>
                  <p class="body-1 white--text">{{ $t('services.sub35') }}</p>
                </div>
              </div>
            </v-card>
          </div>
        </v-card>
      </v-flex>
      <v-flex d-flex xs12 sm6 md2>
        <v-card flat color="grey darken-1" dark>
          <div style="text-align:center">
            <div>
              <v-btn v-if='this.$route.path == "/"' color="white" @click="() => this.$vuetify.goTo('#foo')" fab class="mt-5 purple">
                <v-icon large color="white">search</v-icon>
              </v-btn>
              <v-btn v-else color="white" @click="() => this.$router.push('/support')" fab class="mt-5 purple">
                <v-icon large color="white">search</v-icon>
              </v-btn>
            </div>
            <!-- <div class="subheading">{{ $t('home.support') }}</div> -->
            <v-card flat color="grey darken-1" dark>
              <div style="text-align:center">
                <!-- <v-img :src="images.other" height="200px" /> -->
                <div style="text-align:center">
                  <div class="headline white--text">{{ $t('services.header4') }}</div>
                  <div style="height:20px"></div>
                  <p class="body-1 white--text">{{ $t('services.sub41') }}</p>
                  <p class="body-1 white--text">{{ $t('services.sub42') }}</p>
                  <p class="body-1 white--text">{{ $t('services.sub43') }}</p>
                  <p class="body-1 white--text">{{ $t('services.sub44') }}</p>
                  <p class="body-1 white--text">{{ $t('services.sub45') }}</p>
                </div>
              </div>
            </v-card>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- <v-layout wrap purple lighten-2 pt-5 pb-4>
      <v-flex d-flex xs12 sm12 md12>
        <v-card flat color="purple lighten-2" dark>
          <div style="text-align:center">
            <div class="title">{{ $t('home.button_header') }}</div>
            <div style="height:10px"></div>
            <v-btn large outline round color="white" to="/ecopledge">{{
              $t('home.eco_button')
            }}</v-btn>
          </div>
        </v-card>
      </v-flex>
    </v-layout> -->
  </v-container>
</template>

<script>
export default {
  data() {
    return {
       alert: true,
      // images: {
      //   paper: require('@/assets/img/icons-09-01.png'),
      //   plastic: require('@/assets/img/icons-05.png'),
      //   logo: require('@/assets/img/ecopledge-logo-01.png'),
      //   power: require('@/assets/img/icons-07.png'),
      //   water: require('@/assets/img/icons-08.png')
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0 !important;
}
.v-container {
  padding: 0 !important;
  margin: 0 !important;
}
.v-content {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
