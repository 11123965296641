<template>
  <v-content>
    <section id="hero">
      <v-row no-gutters>
        <div style="height:45px"></div>
        <v-img :src="images.home" :aspect-ratio="2.0" max-height="625" min-height="225">
          <v-theme-provider dark>
            <v-container fill-height>
              <v-row
                align="center"
                class="white--text mx-auto"
                justify="center"
              >
                <v-col class="white--text text-center" cols="12" tag="h1">
                  <span
                    class="font-weight-bold"
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-2' : 'display-3'
                    ]"
                  >
                    iBeck Consulting
                  </span>
                  <br />
                </v-col>
              </v-row>
            </v-container>
          </v-theme-provider>
        </v-img>
      </v-row>
    </section>
  </v-content>
</template>

 <script src="https://cdn.jsdelivr.net/npm/vue@2.x/dist/vue.js"></script>
 <script src="https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.js"></script>
<script>
export default {
  name: 'HomeImage',
  data() {
    return {
  images: {
    home: require('@/assets/it-growth.jpg')
  }
    }
}
}
</script>

