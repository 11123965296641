import firebase from "firebase"

import UserEcopledgesDB from '@/firebase/user-ecopledges-db'
import UsersDB from '@/firebase/users-db'
// import UserEcopledgeDB from '@/firebase/user-ecopledge-db'


export default {
  /**
   * Fetch ecopledges of current signed in user
   */
  getUserEcopledges: async ({ rootState, commit }) => {
    const userEcopledgeDb = new UserEcopledgesDB(
      rootState.authentication.user.id
    )
    const newEcopledges = await userEcopledgeDb.readAll()
    console.log('ecopledges.actions/getUserEcopledges')
    commit('setEcopledges', newEcopledges)
  },

  getEcopledge: async ({ rootState, commit }) => {
    // commit('setEcopledgeLoading', true)
    const userEcopledge = new UserEcopledgesDB(
      rootState.authentication.user.id,
    )
    // console.log('getEcopledge for: ' + rootState.authentication.user.currEcopledge)
    const newEcopledge = await userEcopledge.read(rootState.authentication.user.currEcopledge)
    // console.log('in getEcopledge .. setEcopledge: ' + newEcopledge.id)
    console.log('ecopledges.actions/getEcopledge')
    commit('setEcopledge', newEcopledge)
    // commit('setEcopledgeLoading', false)
  },

  updateEcopledge: async ({ rootState }, payload) => {
    // try {
      const userEcopledge = new UserEcopledgesDB(
        rootState.authentication.user.id
      )
      // console.log('userEcopledge: ' + userEcopledge)
      const updateEcopledge = await userEcopledge.update(payload)
      console.log('updateEcopledge: ' + updateEcopledge)
      // commit('setEcopledge', updateEcopledge)
    // } catch (error) {
    //   console.log(error)
    // throw error;
    // }
  },
  /**
   * Create a new ecopledge for current signed user and reset ecopledge name input
   */
  triggerAddEcopledgeAction: ({ dispatch, rootState }, days) => {
    const ecopledge = {
      userId: rootState.authentication.user.id,
      // userName: rootState.authentication.user.userName,
      inProgress: true,
      epCurrDay: 1,
      epTotalDays: parseInt(days),
      epStartDate: new Date(Date.now()),
      epEndDate: new Date(Date.now() + (days*1000*60*60*24)),
      homeImageDays: 1,
      todayPlasticsDone: false,
      todayReuseDone: false,
    }
    dispatch('createUserEcopledge', ecopledge)
  },
  /**
   * Create a ecopledge for current signed in user
   */
  createUserEcopledge: async ({ commit, dispatch, rootState }, payload) => {
    const userEcopledgeDb = new UserEcopledgesDB(
      rootState.authentication.user.id
    )
    const createdEcopledge = await userEcopledgeDb.create(payload)
    // console.log('createdEcopledge: ', createdEcopledge.id)
    // commit('addEcopledge', createdEcopledge)

    const userBefore = new UsersDB(
      rootState.authentication.user.id
    )
    // const userBefore = rootState.authentication.user
    userBefore.currEcopledge = createdEcopledge.id
    // console.log('created new ecopledge: ' + createdEcopledge.id)
    console.log('ecopledges.actions/createUserEcopledge')
    commit('setEcopledge', createdEcopledge)
    dispatch('authentication/updateUser', userBefore, {root:true})

    const addMessage = firebase.functions().httpsCallable("setUserEcopledge")

    const data = { uid: rootState.authentication.user.id, ecopledge: createdEcopledge.id }

    addMessage(data)
        .then(function(result) {
            console.log(result);
        })
        .catch(function(error) {
            console.log(error);
        });

    // dispatch('getEcopledge')

},


  /**
   * Delete a user ecopledge from its id
   */
  deleteUserEcopledge: async ({ rootState, commit, getters }, ecopledgeId) => {
    if (getters.isEcopledgeDeletionPending(ecopledgeId)) return

    const userEcopledgesDb = new UserEcopledgesDB(
      rootState.authentication.user.id
    )

    commit('addEcopledgeDeletionPending', ecopledgeId)
    await userEcopledgesDb.delete(ecopledgeId)
    commit('removeEcopledgeById', ecopledgeId)
    commit('removeEcopledgeDeletionPending', ecopledgeId)
  }
}
