import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/messaging';

const config = {
  apiKey: 'AIzaSyCZ8wvmTkUyYM6JJE-RqEmBMOLwJlujFT8',
  authDomain: 'ibeck-me.firebaseapp.com',
  databaseURL: 'https://ibeck-me.firebaseio.com',
  projectId: 'ibeck-me',
  storageBucket: 'ibeck-me.appspot.com',
  messagingSenderId: '319970155890',
  appId: '1:319970155890:web:54435a2e363ba6fa'
}

// dev
// const config = {
//   apiKey: 'AIzaSyDK2sw2mZMU4u0q_2X_XQqe-ipIO23Rzn4',
//   authDomain: 'heroestoodev.firebaseapp.com',
//   databaseURL: 'https://heroestoodev.firebaseio.com',
//   projectId: 'heroestoodev',
//   storageBucket: 'heroestoodev.appspot.com',
//   messagingSenderId: '123538503327',
//   appId: '1:123538503327:web:ae452d4cc3387f27'
// }

// prod
// const config = {
//   apiKey: "AIzaSyAUCORlsiXoeQfy3KgbguPLRpCyH0YNDxI",
//   authDomain: "heroestooprod.firebaseapp.com",
//   databaseURL: "https://heroestooprod.firebaseio.com",
//   projectId: "heroestooprod",
//   storageBucket: "",
//   messagingSenderId: "878618471777"
// }

firebase.initializeApp(config)

// const messaging = firebase.messaging()

// export default {
//   messaging
// }