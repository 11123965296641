<template>
  <v-app>
    <div id="app">
      <nav-bar></nav-bar>
      <div class="main-wrapper">
        <router-view />
        <!-- <v-btn icon v-if="$route.name=='home'" @click="getMessagingToken">
          <v-icon>notifications_none</v-icon>
        </v-btn>-->
      </div>
      <new-content-available-toastr
        v-if="newContentAvailable"
        class="new-content-available-toastr"
        :refreshing-app="refreshingApp"
        @refresh="serviceWorkerSkipWaiting"
      ></new-content-available-toastr>
      <apple-add-to-home-screen-modal
        v-if="showAddToHomeScreenModalForApple"
        class="apple-add-to-home-screen-modal"
        @close="closeAddToHomeScreenModalForApple(false)"
      ></apple-add-to-home-screen-modal>
    </div>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import NavBar from '@/components/NavBar'
import NewContentAvailableToastr from '@/components/NewContentAvailableToastr'
import AppleAddToHomeScreenModal from '@/components/AppleAddToHomeScreenModal'

// import firebase from '@/firebase/init'
// import axios from 'axios'

// const { messaging } = firebase

export default {
  components: { NavBar, NewContentAvailableToastr, AppleAddToHomeScreenModal },
  computed: {
    ...mapGetters('app', ['newContentAvailable']),
    ...mapState('app', ['showAddToHomeScreenModalForApple', 'refreshingApp'])
  },
  // mounted() {
  //   this.listenTokenRefresh()
  // },
  methods: {
    ...mapActions('app', [
      'closeAddToHomeScreenModalForApple',
      'serviceWorkerSkipWaiting'
    ])

    // getMessagingToken() {
    //   messaging
    //     .getToken()
    //     .then(async token => {
    //       if (token) {
    //         const currentMessageToken = window.localStorage.getItem(
    //           'messagingToken'
    //         )
    //         console.log('token will be updated', currentMessageToken != token)
    //         if (currentMessageToken != token) {
    //           await this.saveToken(token)
    //         }
    //       } else {
    //         console.log(
    //           'No Instance ID token available. Request permission to generate one.'
    //         )
    //         this.notificationsPermisionRequest()
    //       }
    //     })
    //     .catch(function(err) {
    //       console.log('An error occurred while retrieving token. ', err)
    //     })
    // },
    // notificationsPermisionRequest() {
    //   messaging
    //     .requestPermission()
    //     .then(() => {
    //       this.getMessagingToken()
    //     })
    //     .catch(err => {
    //       console.log('Unable to get permission to notify.', err)
    //     })
    // },
    // listenTokenRefresh() {
    //   const currentMessageToken = window.localStorage.getItem('messagingToken')
    //   console.log('currentMessageToken', currentMessageToken)
    //   if (currentMessageToken) {
    //     messaging.onTokenRefresh(function() {
    //       messaging
    //         .getToken()
    //         .then(function(token) {
    //           this.saveToken(token)
    //         })
    //         .catch(function(err) {
    //           console.log('Unable to retrieve refreshed token ', err)
    //         })
    //     })
    //   }
    // },
    // saveToken(token) {
    //   console.log('tokens', token)
    //   axios
    //     .post(
    //       `https://us-central1-heroestoodev.cloudfunctions.net/GeneralSubscription`,
    //       { token }
    //     )
    //     .then(response => {
    //       window.localStorage.setItem('messagingToken', token)
    //       console.log(response)
    //     })
    //     .catch(err => {
    //       console.log(err)
    //     })
    // }
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
}
.v-content {
  padding: 0 !important;
}
.v-container {
  padding: 0 !important;
}
.v-app {
  padding: 0 !important;
}
.container {
  padding: 0 !important;
  margin: 0;
}
.content {
  padding: 0 !important;
}
body {
  margin: 0;

  a {
    font-weight: 500;
    text-decoration: none;
  }

  #app {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    color: #2c3e50;

    .new-content-available-toastr {
      position: absolute;
      top: 100px;
      right: 10px;
      z-index: 1001;
    }

    .apple-add-to-home-screen-modal {
      position: absolute;
      bottom: 0;
      right: 0;
      top: 0;
      left: 0;
      height: fit-content;
      width: fit-content;
      margin: auto;
      z-index: 1000;
    }

    .main-wrapper {
      margin-top: 3.1rem;
      padding: 0px !important;
      margin: 0 !important;

      .page-wrapper {
        width: 60%;
        margin: auto;

        @media screen and (max-width: 1000px) {
          width: 100%;
        }
      }
    }
  }
}
</style>
