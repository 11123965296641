import { find } from 'lodash'

export default {
  /**
   * Check if an record has deletion pending
   */
  isRecordDeletionPending: state => recordId =>
    state.recordDeletionPending.includes(recordId),

  /**
   * Get record by id
   */
  getRecordById: state => recordId =>
    find(state.records, record => record.id === recordId),

  /**
   * Get records by ecopledge
   */
  getRecordsByEcopledge: state => ecopledgeId =>
    find(state.records, record => record.ecopledgeId === ecopledgeId)
}
