<template>
  <v-container app fluid>
    <v-layout align-space-between justify-center wrap >
      <v-flex d-flex>
        <v-card flat class="pt-4 pb-4 purple">
          <div style="text-align:center">
            <p>
              <i class="fa fa-envelope"></i>
              <a href="mailto:ian@ibeck.me"> ian@ibeck.me</a>
            </p>
            <p><i class="fa fa-phone"></i> +852 9449-4493</p>
          </div>
          <div style="text-align:center">
            <v-btn
              round
              dark
              color="indigo darken-3"
              style="font-size: 30px;"
              href="https://www.linkedin.com/in/ibeck/"
              target="_blank"
            >
              <i class="fa fa-linkedin" aria-hidden="true"></i>
            </v-btn>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'webfooter',
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0 !important;
}
.v-container {
  padding: 0 !important;
  margin: 0;
}
.v-content {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
