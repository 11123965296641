import Vue from 'vue'
import Router from 'vue-router'
// import goTo from 'vuetify/es5/services/goto'
import firebase from 'firebase'
import Head from 'vue-head'
import { isNil } from 'lodash'
import Home from '@/views/Home'
import CheckSignin from '@/views/CheckSignin'
import store from '@/store'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
})

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  // scrollBehavior: (to, from, savedPosition) => {
  //   let scrollTo = 0

  //   if (to.hash) {
  //     scrollTo = to.hash
  //   } else if (savedPosition) {
  //     scrollTo = savedPosition.y
  //   }

  //   return goTo(scrollTo)
  // },
  routes: [
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        requiresAuth: false,
        authNotRequired: true
      }
    },
    {
      path: '/check-signin',
      name: 'check-signin',
      component: CheckSignin,
      meta: {
        requiresAuth: false,
        authNotRequired: true
      }
    },
    {
      path: '/signin',
      name: 'signin',
      component: () =>
        import(/* webpackChunkName: "client-chunk-signin" */ '@/views/Signin.vue'),
      meta: {
        requiresAuth: false,
        authNotRequired: true
      }
    },
    // {
    //   path: '/contact',
    //   name: 'ContactUs',
    //   component: () =>
    //     import(/* webpackChunkName: "client-chunk-signin" */ '@/views/ContactUs.vue'),
    //   meta: {
    //     requiresAuth: false,
    //     authNotRequired: true
    //   }
    // },
    {
      path: '/services',
      name: 'services',
      component: () =>
        import(/* webpackChunkName: "client-chunk-signin" */ '@/views/Services.vue'),
      meta: {
        requiresAuth: false,
        authNotRequired: true
      }
    },
    {
      path: '/strategy',
      name: 'strategy',
      component: () =>
        import(/* webpackChunkName: "client-chunk-signin" */ '@/views/Strategy.vue'),
      meta: {
        requiresAuth: false,
        authNotRequired: true
      }
    },
    {
      path: '/development',
      name: 'development',
      component: () =>
        import(/* webpackChunkName: "client-chunk-signin" */ '@/views/Development.vue'),
      meta: {
        requiresAuth: false,
        authNotRequired: true
      }
    },
    {
      path: '/branding',
      name: 'branding',
      component: () =>
        import(/* webpackChunkName: "client-chunk-signin" */ '@/views/Branding.vue'),
      meta: {
        requiresAuth: false,
        authNotRequired: true
      }
    },
    {
      path: '/support',
      name: 'support',
      component: () =>
        import(/* webpackChunkName: "client-chunk-signin" */ '@/views/Support.vue'),
      meta: {
        requiresAuth: false,
        authNotRequired: true
      }
    },
    // {
    //   path: '/ecopledge',
    //   name: 'ecopledge',
    //   component: () =>
    //     import(/* webpackChunkName: "client-chunk-signin" */ '@/views/Ecopledge.vue'),
    //   meta: {
    //     requiresAuth: false,
    //     authNotRequired: true
    //   }
    // },
    {
      path: '/about',
      name: 'about',
      component: () =>
        import(/* webpackChunkName: "client-chunk-signin" */ '@/views/About.vue'),
      meta: {
        requiresAuth: false,
        authNotRequired: true
      }
    },
    // {
    //   path: '/myhome',
    //   name: 'MyEcopledge',
    //   component: () =>
    //     import(/* webpackChunkName: "client-chunk-products" */ '@/views/MyEcopledge.vue'),
    //   children: [
    //     {
    //       path: '/myhome',
    //       name: 'MyHome',
    //       component: () =>
    //         import(/* webpackChunkName: "client-chunk-products" */ '@/views/myecopledge/MyHome.vue'),
    //       meta: {
    //         requiresAuth: true,
    //         adminAuth: true,
    //         collectiveAuth: false,
    //         individualAuth: true,
    //         authNotRequired: false
    //       }
    //     },
    //     {
    //       path: '/mystats',
    //       name: 'MyStats',
    //       component: () =>
    //         import(/* webpackChunkName: "client-chunk-products" */ '@/views/myecopledge/MyStats.vue'),
    //       meta: {
    //         requiresAuth: true,
    //         adminAuth: true,
    //         collectiveAuth: false,
    //         individualAuth: true,
    //         authNotRequired: false
    //       }
    //     },
    //     {
    //       path: '/myreuse',
    //       name: 'MyReuse',
    //       component: () =>
    //         import(/* webpackChunkName: "client-chunk-products" */ '@/views/myecopledge/MyReuse.vue'),
    //       meta: {
    //         requiresAuth: true,
    //         adminAuth: true,
    //         collectiveAuth: false,
    //         individualAuth: true,
    //         authNotRequired: false
    //       }
    //     },
    //     {
    //       path: '/myplastics',
    //       name: 'MyPlastics',
    //       component: () =>
    //         import(/* webpackChunkName: "client-chunk-products" */ '@/views/myecopledge/MyPlastics.vue'),
    //       meta: {
    //         requiresAuth: true,
    //         adminAuth: true,
    //         collectiveAuth: false,
    //         individualAuth: true,
    //         authNotRequired: false
    //       }
    //     },
    //     {
    //       path: '/myme',
    //       name: 'MyMe',
    //       component: () =>
    //         import(/* webpackChunkName: "client-chunk-products" */ '@/views/myecopledge/MyMe.vue'),
    //       meta: {
    //         requiresAuth: true,
    //         adminAuth: true,
    //         collectiveAuth: false,
    //         individualAuth: true,
    //         authNotRequired: false
    //       }
    //     },
    //     {
    //       path: '/myonboard',
    //       name: 'MyOnboard',
    //       component: () =>
    //         import(/* webpackChunkName: "client-chunk-products" */ '@/views/myecopledge/MyOnboard.vue'),
    //       meta: {
    //         requiresAuth: true,
    //         adminAuth: true,
    //         collectiveAuth: false,
    //         individualAuth: true,
    //         authNotRequired: false
    //       }
    //     }
    //   ]
    // },
    {
      path: '/ourdashboard',
      name: 'ourdashboard',
      component: () =>
        import(/* webpackChunkName: "client-chunk-products" */ '@/views/ourecopledge/ourDashboard.vue'),
      meta: {
        requiresAuth: true,
        adminAuth: true,
        collectiveAuth: true,
        individualAuth: false,
        authNotRequired: false
      }
    },
    // {
    //   path: '/adminsignin',
    //   name: 'adminsignin',
    //   component: () =>
    //     import(/* webpackChunkName: "client-chunk-products" */ '@/views/admin/AdminSignIn.vue')
    // },
    {
      path: '/admindashboard',
      name: 'admindashboard',
      component: () =>
        import(/* webpackChunkName: "client-chunk-products" */ '@/views/admin/AdminDashboard.vue'),
      meta: {
        requiresAuth: true,
        adminAuth: true,
        collectiveAuth: false,
        individualAuth: false,
        authNotRequired: false
      }
    },
    // {
    //   path: '/products',
    //   name: 'products',
    //   component: () =>
    //     import(/* webpackChunkName: "client-chunk-products" */ '@/views/Products.vue')
    // },
    // {
    //   path: '/products/:id',
    //   name: 'product',
    //   props: true,
    //   component: () =>
    //     import(/* webpackChunkName: "client-chunk-product-details" */ '@/views/Product.vue')
    // },
    { path: '*', redirect: '/home' }
  ]
})

/**
 * Handle user redirections
 */
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  if (
    !(to.meta && to.meta.authNotRequired) &&
    isNil(store.state.authentication.user)
  ) {
    const path =
      store.state.authentication.user === null ? '/signin' : '/check-signin'
    return next(`${path}?redirectUrl=${to.path}`)
  }
  next()
})

export default router
//   firebase.auth().onAuthStateChanged(userAuth => {

//     if (userAuth) {
//       firebase.auth().currentUser.getIdTokenResult()
//         .then(function ({
//           claims
//         }) {
//           if (claims.individual) {
//             if (to.path !== '/myhome')
//               return next({
//                 path: '/myhome',
//               })
//           } else if (claims.admin) {
//             if (to.path !== '/admindashboard')
//               return next({
//                 path: '/admindashboard',
//               })
//           } else if (claims.collective) {
//             if (to.path !== '/ourdashboard')
//               return next({
//                 path: '/ourdashboard',
//               })
//           }

//         })
//     } else {
//       if (to.matched.some(record => record.meta.requiresAuth)) {
//         next({
//           path: '/signin',
//           query: {
//             redirect: to.fullPath
//           }
//         })
//       } else {
//         next()
//       }
//     }
// })
// next()
// })

// const currentUser = store.state.authentication.user
// const requiresAuth = to.meta.requiresAuth

// console.log("currentUser: " + currentUser)
// console.log(`Updating slug from ${from} to ${to}`)

// if (to.meta.requiresAuth && isNil(store.state.authentication.user)) {
//   console.log("to signin")
//   next('/signin')
//   }
//   else if (to.meta.requiresAuth && store.state.authentication.user) {
//     console.log("to myhome")
//     next('/myhome')
//   }
//   else if (!to.meta.requiresAuth && store.state.authentication.user) {
//     console.log("to home")
//     next('/home')
//   }
// else {
//   next()
// }
// if (to.meta.requiresAuth) {
//   const authUser = store.state.authentication.user
//   if (!authUser || !authUser.token) {
//     next({name:'home'})
//     console.log("next home")
//   }
//   else if (to.meta.adminAuth) {
//     console.log('to.meta.adminAuth '  + authUser.userType)
//     const authUser = store.state.authentication.user
//     if (authUser.userType === 'admin') {
//       next()
//     } else {
//       console.log('else '  + authUser.userType)
//       next('/ourdashboard')
//     }
//   }else {
//             next()
//         }
//   }
//   else if (to.meta.individualAuth) {
//     console.log('in router.beforeEach '  + authUser.userType)
//     const authUser = store.state.authentication.user
//     if (authUser.userType === 'individual') {
//       next()
//     }else {
//       console.log('Im in admin')
//       next('/admindashboard')
//     }
//   }
// else
//   next()
