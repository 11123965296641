export default {
  /* Ecopledge */
  setEcopledge: (state, ecopledge) => (state.ecopledge = ecopledge),
  addEcopledge: (state, ecopledge) => state.ecopledges.push(ecopledge),

  /* Ecopledges */
  setEcopledges: (state, ecopledges) => (state.ecopledges = ecopledges),

  removeEcopledgeById: (state, ecopledgeId) => {
    const index = state.ecopledges.findIndex(
      ecopledge => ecopledge.id === ecopledgeId
    )
    state.ecopledges.splice(index, 1)
  },

  /* Ecoplege deletion */
  addEcopledgeDeletionPending: (state, ecopledgeId) =>
    state.ecopledgeDeletionPending.push(ecopledgeId),
  removeEcopledgeDeletionPending: (state, ecopledgeId) => {
    const index = state.ecopledges.findIndex(
      ecopledge => ecopledge.id === ecopledgeId
    )
    state.ecopledgeDeletionPending.splice(index, 1)
  },

  /* Ecopledge creation */
  setEcopledgeCreationPending: (state, value) =>
    (state.ecopledgeCreationPending = value),

  /* Ecopledge update */
  setEcopledgeUpdatePending: (state, value) =>
    (state.ecopledgeUpdatePending = value),

  /* Ecopledge loading */
  setEcopledgeLoading: (state, value) =>
    (state.ecopledgeLoading = value)
}
