import { isNil } from 'lodash'
import router from '@/router'
import { createNewUserFromFirebaseAuthUser } from '@/misc/helpers'
import UsersDB from '@/firebase/users-db'
// import ecopledges from '../ecopledges'

export default {
  getUsers: async ({ rootState, commit }) => {
      const userDb = new UsersDB(rootState.authentication.user.id)
      const allUsers = await userDb.readAll()
      console.log('auth.actions/updateUser/setUsers')
      commit('setUsers', allUsers)
  },

  updateUser: async ({ rootState, commit }, payload ) => {
    try {
      const userFromFirebase = await new UsersDB().read(rootState.authentication.user.id)
      userFromFirebase.currEcopledge = payload.currEcopledge
      console.log('auth.actions/updateUser/setUser')
      commit('setUser', userFromFirebase)
      console.log("Updating ecopledge from: " + userFromFirebase.currEcopledge + " with: " + payload.currEcopledge)
      const updateUser = new UsersDB(
        rootState.authentication.user.id
      )
      const afterUpdate = await updateUser.update(userFromFirebase)

      console.log('afterUpdate user, currEcopledge is: ' + afterUpdate)

    } catch (error) {
      console.log(error)
    throw error;
    }
  },
    updateUserName: async ({ commit, rootState }, payload ) => {
    try {
      // console.log("payload: " + payload.uid)
      console.log("user.id:" + rootState.authentication.user.id)
      const userFromFirebase = await new UsersDB().read(rootState.authentication.user.id)
      userFromFirebase.displayName = payload.name

      const updateUser = new UsersDB(
        rootState.authentication.user.id
      )
      const afterUpdate = await updateUser.update(userFromFirebase)

      console.log('auth.actions/updateUser/updateUserName')
      commit('setUser', afterUpdate)
    } catch (error) {
      console.log(error)
    throw error;
    }
  },
  /**
   * Callback fired when user signin
   */
  signin: async ({ commit, rootState }, firebaseAuthUser) => {
    const userFromFirebase = await new UsersDB().read(firebaseAuthUser.uid)

    const user = isNil(userFromFirebase)
      ? await createNewUserFromFirebaseAuthUser(firebaseAuthUser)
      : userFromFirebase

    commit('setUser', user)
    // console.log('rootState.authentication.user.currEcopledge ' + rootState.authentication.user.currEcopledge)
    // console.log('user.currEcopledge ' + user.currEcopledge)

    if (rootState.authentication.user.userType === 'individual') {
      if (rootState.authentication.user.currEcopledge === 'initial') {
        router.push('/myonboard')
      } else {
        router.push('/myhome')
      }
    } else if (rootState.authentication.user.userType === 'collective') {
        router.push('/ourdashboard')
     } else if (rootState.authentication.user.userType === 'admin') {
        router.push('/admindashboard')
     }
    // dispatch('products/getUserProducts', null, { root: true })
  },

  /**
   * Callback fired when user signout
   */
  signout: ({ commit }) => {
    commit('setUser', null)
    commit('ecopledges/setEcopledges', null, { root: true })

    const currentRouter = router.app.$route
    if (!(currentRouter.meta && currentRouter.meta.authNotRequired)) {
      router.push('/signin')
    }
  }

  //  updateUserStart: async ({ commit, dispatch }, firebaseAuthUser) => {
  //   alert("in updateUserStart")
  //   const userFromFirebase = await new UsersDB().read(firebaseAuthUser.uid)

  //   const user = isNil(userFromFirebase)
  //     ? await updateUserFromFirebaseAuthUser(firebaseAuthUser)
  //     : userFromFirebase

  //   commit('setUser', user)
  //   if (user.epCurrDay == 0) {
  //     router.push('/myonboard')
  //   }
  // },
}
