<template>
  <v-app id="home">
    <v-content>
      <top-image />
      <over-view />
      <web-footer />
    </v-content>
  </v-app>
</template>

<script>
import TopImage from '@/views/home/TopImage.vue'
import OverView from '@/views/home/Overview.vue'
import WebFooter from '@/components/webFooter.vue'

export default {
  components: {
    TopImage,
    OverView,
    WebFooter
  }
}
</script>
